import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAP_ID, GOOGLE_MAPS_API_KEY } from "../../globals/constants";
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 50.980399479364435,
  lng: 4.976246197738235,
};

const locationMarker = {
  lat: 50.980104479364435,
  lng: 4.976246197738235,
};

const parkingSpots = [
  {
    pos: { lat: 50.98041353570287, lng: 4.975471041443691 },
    color: "#0D919F",
    name: "P1",
  },
  {
    pos: { lat: 50.981696915727674, lng: 4.980725488748099 },
    color: "#C219A3",
    name: "P2",
  },
  {
    pos: { lat: 50.97909826729805, lng: 4.974916446497144 },
    color: "#262626",
    name: "P3",
  },
];

const createParkingMarker = (color: string, name: string) => {
  const div = document.createElement("div");
  div.style.backgroundColor = color;
  div.style.color = "white";
  div.style.width = "30px";
  div.style.height = "30px";
  div.style.display = "flex";
  div.style.alignItems = "center";
  div.style.justifyContent = "center";
  div.style.borderRadius = "50%";
  div.style.fontWeight = "bold";
  div.innerText = name;

  return div;
};

const createLocationMarker = () => {
  const div = document.createElement("div");
  div.style.width = "80px";
  div.style.height = "25px";
  div.style.backgroundColor = "#8AC219";
  div.style.borderRadius = "10px";
  div.style.position = "relative";
  div.style.display = "flex";
  div.style.justifyContent = "center";
  div.style.alignItems = "center";
  div.style.color = "white";
  div.style.fontWeight = "bold";
  div.innerText = "De-Cameleon";

  const triangle = document.createElement("div");
  triangle.style.position = "absolute";
  triangle.style.bottom = "-10px";
  triangle.style.left = "50%";
  triangle.style.transform = "translateX(-50%)";
  triangle.style.width = "0";
  triangle.style.height = "0";
  triangle.style.borderLeft = "15px solid transparent";
  triangle.style.borderRight = "15px solid transparent";
  triangle.style.borderTop = "10px solid #8AC219";

  div.appendChild(triangle);

  return div;
};

export const Map = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["marker"],
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    if (isLoaded && window.google && map) {
      parkingSpots.forEach((spot) => {
        new window.google.maps.marker.AdvancedMarkerElement({
          map,
          position: spot.pos,
          title: "Parking Spot",
          content: createParkingMarker(spot.color, spot.name),
        });
      });
      new window.google.maps.marker.AdvancedMarkerElement({
        map,
        position: locationMarker,
        title: "Parking Spot",
        content: createLocationMarker(),
      });
    }
  }, [isLoaded, map]);

  if (!isLoaded) return <p>Loading Map...</p>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={16.5}
      options={{ mapId: GOOGLE_MAP_ID }}
      onLoad={(map) => setMap(map)}
    />
  );
};
