import { Box, Button, Typography } from "@mui/material";
import { StyledAssortimentCard } from "./AssortimentCard.styled";
import { AssortimentData } from "../../models/Types";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { ScrollToTop } from "../ScrollToTop";
import { isMobile } from "react-device-detect";

type AssortimentCardProps = {
  background: string;
  assortimentData: AssortimentData;
  selectedAssortiment?: AssortimentData;
  onClick: (assortimentData: AssortimentData) => void;
  onClose: () => void;
};

export const AssortimentCard = ({
  background,
  assortimentData,
  selectedAssortiment,
  onClick,
  onClose,
}: AssortimentCardProps) => {
  const { dictionary } = useContext(LanguageContext);
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);

  useEffect(() => {
    setTriggerUpdate(false);
  }, [triggerUpdate]);

  const onReadMoreClick = () => {
    setTriggerUpdate(true);
    onClick(assortimentData);
  };

  console.log(isMobile);

  return (
    <StyledAssortimentCard
      background={background}
      selectedAssortimentIndex={selectedAssortiment?.index}
      currentAssortimentIndex={assortimentData.index}
    >
      {isMobile ? triggerUpdate ? <ScrollToTop offset={400} /> : <></> : <></>}
      {assortimentData.icon}
      <Typography variant="h2">{assortimentData.title}</Typography>
      <Typography variant="h3">{assortimentData.subTitle}</Typography>
      <Typography variant="h4">{assortimentData.description}</Typography>
      {selectedAssortiment ? (
        <Button onClick={onClose}>{dictionary.buttons.close}</Button>
      ) : (
        <Button onClick={onReadMoreClick}>{dictionary.buttons.readmore}</Button>
      )}
    </StyledAssortimentCard>
  );
};
