import { StyledFooter } from "./Footer.styled";
import decameleon_logo_stepstone from "../../images/decameleon_logo_stepstone.svg";
import footerWave from "../../images/footer_wave.svg";

import { Box, Typography } from "@mui/material";
import { Divider } from "./Divider";
import {
  Briefcase,
  Cookie,
  CurrencyEur,
  EnvelopeSimple,
  FacebookLogo,
  HouseLine,
  HouseSimple,
  Info,
  Keyhole,
  ListBullets,
  ListDashes,
  Package,
  Phone,
  QuestionMark,
} from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../../globals/LanguageContext";

export const Footer = () => {
  const navigate = useNavigate();
  const { dictionary } = useContext(LanguageContext);

  return (
    <StyledFooter>
      <img
        className="footerWave"
        src={footerWave}
        alt="Footer wave"
        loading="lazy"
      />
      <Box className="header">
        <Box className="imageWrapper">
          <img
            src={decameleon_logo_stepstone}
            alt="de-cameleon logo in stepstone"
            loading="lazy"
          />
        </Box>
        <Box className="infoWrapper">
          <Typography variant="h2">Gegevens</Typography>
          <Divider />
          <Box className="listItem link">
            <Phone size={30} color="#0D555D" />
            <a href="tel:+32494993971">
              <Typography variant="h4">+32 494/99.39.71</Typography>
            </a>
          </Box>
          <Box className="listItem link">
            <EnvelopeSimple size={30} color="#0D555D" />
            <a href="mailto:info@decameleon.be">
              <Typography variant="h4">info@decameleon.be</Typography>
            </a>
          </Box>
          <Box className="listItem link">
            <HouseLine size={30} color="#0D555D" />
            <a href="https://www.google.com/maps/place/Basilieklaan+10,+3270+Scherpenheuvel-Zichem/@50.9800758,4.9749587,18z/data=!3m1!4b1!4m6!3m5!1s0x47c1412f82ffea95:0x26530443b78355be!8m2!3d50.9800758!4d4.9762462!16s%2Fg%2F11c25syk0_?entry=ttu&g_ep=EgoyMDI1MDExNS4wIKXMDSoASAFQAw%3D%3D">
              <Typography variant="h4">
                Basilieklaan 10 3270 Scherpenheuvel
              </Typography>
            </a>
          </Box>
          <Box className="listItem link">
            <FacebookLogo size={30} color="#0D555D" />
            <a href="https://www.facebook.com/de.cameleon.2024">
              <Typography variant="h4">@de.cameleon.2024</Typography>
            </a>
          </Box>
          <Box className="listItem">
            <Briefcase size={30} color="#0D555D" />
            <Typography variant="h4">BE 0756.838.144</Typography>
          </Box>
        </Box>
        <Box className="infoWrapper">
          <Typography variant="h2">Links</Typography>
          <Divider />
          <Box className="listItem link" onClick={() => navigate("/")}>
            <HouseSimple size={30} color="#0D555D" />
            <Typography variant="h4">{dictionary.footer.home}</Typography>
          </Box>
          <Box className="listItem link" onClick={() => navigate("/aboutus")}>
            <QuestionMark size={30} color="#0D555D" />
            <Typography variant="h4">{dictionary.footer.aboutus}</Typography>
          </Box>
          <Box
            className="listItem link"
            onClick={() => navigate("/assortiment")}
          >
            <Package size={30} color="#0D555D" />
            <Typography variant="h4">
              {dictionary.footer.assortiment}
            </Typography>
          </Box>
          <Box className="listItem link" onClick={() => navigate("/pricing")}>
            <CurrencyEur size={30} color="#0D555D" />
            <Typography variant="h4">{dictionary.footer.prices}</Typography>
          </Box>
          <Box className="listItem link" onClick={() => navigate("/contact")}>
            <Info size={30} color="#0D555D" />
            <Typography variant="h4">{dictionary.footer.contact}</Typography>
          </Box>
        </Box>
        <Box className="infoWrapper">
          <Typography variant="h2">Privacy & Cookies</Typography>
          <Divider />
          <Box
            className="listItem link"
            onClick={() => navigate("/info/conditions")}
          >
            <ListDashes size={30} color="#0D555D" />
            <Typography variant="h4">Algemene voorwaarden</Typography>
          </Box>
          <Box
            className="listItem link"
            onClick={() => navigate("/info/privacy")}
          >
            <Keyhole size={30} color="#0D555D" />
            <Typography variant="h4">Privacybeleid</Typography>
          </Box>
          <Box
            className="listItem link"
            onClick={() => navigate("/info/cookies")}
          >
            <Cookie size={30} color="#0D555D" />
            <Typography variant="h4">Cookiebeleid</Typography>
          </Box>
        </Box>
      </Box>
      <Box className="copyright">
        <Typography variant="h4">
          De-Cameleon sinds 2025 - Copyright © All rights reserved
        </Typography>
      </Box>
    </StyledFooter>
  );
};
