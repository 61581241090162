import { styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledContactForm = styled("form")(
  ({ theme }: StyledComponentProps) => ({
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    width: "40rem",
    background: theme.color.background,
    boxShadow: `0px 0px 3px 3px ${theme.contrast.lighter}`,
    borderRadius: "25px",
    justifyContent: "space-evenly",
    gap: "1rem",
    ".contactformrow": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      h4: {
        fontWeight: "bold",
        color: theme.color.dark,
        span: {
          color: theme.color.contrastColor,
          fontFamily: "'Product Sans', sans-serif",
          fontWeight: "bold",
          padding: ".25rem",
          right: "0",
        },
      },
      "input, textarea": {
        width: "30rem",
      },
      p: {
        fontFamily: "'Product Sans', sans-serif",
        fontWeight: "bold",
        position: "absolute",
        right: "0",
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
    },
    ".row:last-of-type p": {
      alignItems: "start",
    },
    ".message": {
      alignItems: "start",
      h4: {
        paddingTop: ".5rem",
      },
    },
    button: {
      width: "50%",
      alignSelf: "flex-end",
      background: theme.color.contrastColor,
      "&:hover": {
        background: theme.color.main,
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
      width: "95%",
      boxSizing: "border-box",
      padding: "0",
      alignItems: "center",
      paddingBlock: "2rem",
      ".contactformrow": {
        flexDirection: "column",
        alignItems: "flex-start",
        p: {
          height: "min-content",
          top: "-1.5rem",
        },
        "input[type='text'], textarea": {
          width: "16rem",
          minWidth: "100%",
          maxWidth: "100%",
        },
      },
      ".confirmation": {
        width: "90%",
        flexDirection: "row",
        alignItems: "center",
        "input[type='checkbox']": {
          width: "3rem",
        },
        ".Mui-error": {
          left: "3.5rem",
          bottom: "0",
          top: "4.5rem",
          padding: "0",
          height: "min-content",
          width: "fit-content",
        },
      },
      button: {
        width: "80%",
        alignSelf: "center",
      },
    },
  })
);
