import { Box, Typography } from "@mui/material";
import { StyledAssortiment } from "./Assortiment.styled";
import wave_background from "../../images/Assortiments/wave_background.png";
import { AssortimentCard } from "../../components/assortimentCard/AssortimentCard";
import { useContext, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { useTextRenderer } from "../../hooks/useTextrenderer";
import stepstones from "../../images/Assortiments/stepstones_background-v2.png";
import stepstone from "../../images/Assortiments/stepstone.svg";
import { AssortimentData } from "../../models/Types";

import {
  HeartHalf,
  Lego,
  PersonSimpleRun,
  PuzzlePiece,
} from "@phosphor-icons/react";
import { MoreInfoCard } from "../../components/moreInfoCard/MoreInfoCard";
import { ScrollToTop } from "../../components/ScrollToTop";

const assortimentCards: AssortimentData[] = [
  {
    index: 1,
    title: "Kinderen",
    subTitle: "Vanaf 7j",
    icon: <Lego size={75} weight="fill" color={"#0D555D"} />,
    description:
      "Je bent een uniek persoon. Samen gaan we op zoek naar jouw kwaliteiten en sterktes om van daaruit te werken aan de problemen waar je tegenaan loopt.",
    link: "/",
    backgroundColor: "#10AEC0",
    info: {
      section1:
        "[bold]Kinderen[/bold] met (een vermoeden van) autisme ontwikkelen, zoals alle kinderen, op hun eigen manier en met hun eigen bijzonderheden. Toch hebben ze soms extra ondersteuning nodig. Aan de hand van spel, creatieve werkvormen en kindgerichte gespreksvormen probeer ik binnen een gemoedelijke sfeer eerst met het kind een verbinding aan te gaan.[/br][/br] Het kind krijgt de nodige ruimte en (puzzel)tijd om zijn emoties, bijhorende gedachten en gedrag te erkennen en in kaart te brengen.Ook gaan we samen met de ouders op 'ontdekkingstocht' naar de juiste tools en strategieën om met de uitdagingen om te gaan.",
      section2:
        "[list]Psycho-educatie en aanvaarden diagnose|Aan de hand van allerlei doe-opdrachten en inleefmomenten kennis en inzichten verwerven.|Zelfredzaamheid ondersteunen a.d.h.v. stappenplannen, visuele ondersteuning,…|Prikkelverwerking in kaart brengen (sensorisch profiel)|Beter leren omgaan met prikkels|Omgaan met stress en angsten[/list]",
      section3:
        "[list]Verbeteren zelfbeeld|Werken aan welbevinden thuis en op school|Werken aan sociale vaardigheden|Communicatieve vaardigheden|Situaties in kaart brengen en op zoek gaan naar ‘gezonde’ coping strategieën|Versterken van eigen draag- en veerkracht|...[/list]",
    },
  },
  {
    index: 2,
    title: "Jongeren",
    icon: <PersonSimpleRun size={75} color={"#0D555D"} />,
    description:
      "Heb je (een vermoeden van) autisme en zit je met vragen? Loop je vast op school of bots je op bepaalde hindernissen? Praat er eens over, dat lucht op!",
    link: "/",
    backgroundColor: "#10ACBD",
    info: {
      section1:
        "Een [bold]jongere of jongvolwassene[/bold] raakt in een bepaalde fase van zijn/haar leven wel eens helemaal in de knoop met zichzelf. Dat is niet anders bij jongeren met (een vermoeden van) autisme. Het is dan niet altijd zo eenvoudig om te gaan met negatieve ervaringen, moeilijke emoties, gedachten die je blijven achtervolgen. Of je loopt vast op school. Ook al doe je steeds je best, het blijft moeilijk voor jou om je zowel op sociaal vlak als op leergebied je te blijven handhaven. Dan wordt het allemaal even te veel en raak je uit balans.[/br][/br]Een coach kan hierin het ontbrekend puzzelstukje bezitten dat nodig is om de 'mindset' van de jongere opnieuw op een positieve manier te activeren.",
      section2:
        "Dus heb je hulp nodig bij:[/br][list]Psycho-educatie en aanvaarden diagnose|Vergroten van inzicht in ASS|Prikkelverwerking in kaart brengen (sensorisch profiel)|Beter leren omgaan met prikkels|Omgaan met emoties, woedebuien en piekergedachten[/list]",
      section3:
        "[list]Omgaan met stress en angsten|Verbeteren zelfbeeld|Werken aan sociale vaardigheden|Organisatie, structuur en tijdsbesef, hulp bij het uitwerken van verduidelijking zoals stappenplannen|Inspelen op concrete moeilijkheden die zich voordoen bv op school, vrije tijd, thuis, …|...[/list]",
    },
  },
  {
    index: 3,
    title: "Volwassenen",
    icon: <PuzzlePiece size={75} color={"#0D555D"} />,
    description:
      "Het je het gevoel dat je steeds maar alle ballen in de lucht moet houden. Wil je minder stress en meer rust in je leven? Via coaching krijg je meer inzicht in jezelf en leer je grenzen aangeven.",
    link: "/",
    backgroundColor: "#10A9B9",
    info: {
      section1:
        "[bold]Volwassenen[/bold] met (een vermoeden van) autisme kunnen botsen op onbegrip vanuit hun omgeving en hebben vragen over hun diagnose. Mogelijk ervaar je moeilijkheden in de dagelijkse ordening van activiteiten of heb je het moeilijk om naast je werk ook nog alle huishoudelijke taken in te plannen.[/br][/br]Soms voel je je angstig en onzeker of is het moeilijk om duidelijk te maken wat je voelt en nodig hebt. In de gesprekken leggen we het accent op je eigen krachten en competenties. Via coaching krijg je meer inzicht in jezelf en leer je grenzen aangeven.",
      section2:
        "[list]Vergroten van inzicht in ASS|Verwerking van diagnose|Op zoek gaan naar je identiteit|Werken aan sociale en relationele vaardigheden|Bevorderen communicatieve vaardigheden|Een goede balans tussen inspanning en ontspanning vinden|Omgaan met stress|Verbeteren zelfbeeld|Piekergedachten leren hanteren[/list]",
      section3:
        "[list]Inzicht krijgen van eigen kwaliteiten en sterktes|Zoeken naar oplossingen voor dagdagelijkse problemen (bv. organisatie van huishouden, problemen op het werk,…)|Omgaan met tijd en tijdsdruk: invullen van vrije tijd, begeleiden bij plannen en organiseren, omgaan met verandering, verminderen van uitstelgedrag, leren omgaan met stress en faalangst|Aanpakken van autistische burnout|Hulp bij onderlinge verwachtingen en perfectionisme|...[/list]",
    },
  },
  {
    index: 4,
    title: "Netwerk",
    subTitle: "Ouders, Partner, Grootouders",
    icon: <HeartHalf size={75} weight="fill" color={"#0D555D"} />,
    description:
      "Heb je iemand in jouw omgeving met (een vermoeden van) autisme en zit je met vragen of heb je zelf nood aan een gesprek?",
    link: "/",
    backgroundColor: "#08A4B5",
    info: {
      section1:
        "[bold]Ouders[/bold] van een kind (met een vermoeden van) autisme hebben vaak een parcours met hindernissen achter de rug of hebben vragen over de opvoeding en ontwikkeling van hun kind. Oudercoaching geeft houvast in de zoektocht om je kind nog beter te begrijpen en je opvoeding en zorg te laten aansluiten bij de noden van je kind.[/br][/br]",
      section2:
        "Zou je graag je partner of (klein)kind met autisme echt leren begrijpen? Elke persoon met autisme is anders en heeft dus nood aan een andere aanpak. Pas wanneer je weet wat autisme is en hoe het autisme zich bij jouw [bold]partner of (klein)kind[/bold] uit, kunnen we samen ontdekken welke aanpak in jouw situatie aangewezen is.",

      section3:
        "[bold]Dus blijf er niet mee zitten:[/bold][/br][list]als je nood hebt aan meer informatie|als je nood hebt aan verduidelijking van situaties of gedrag|als je het gevoel hebt vast te lopen bij een specifiek probleem|als je elkaar wil leren begrijpen, waarderen en respecteren|als je eens wil ventileren[/list]",
    },
  },
];

export const Assortiment = () => {
  const [selectedAssortiment, setSelectedAssortiment] = useState<
    AssortimentData | undefined
  >(undefined);
  const { dictionary } = useContext(LanguageContext);
  const { renderText } = useTextRenderer();

  return (
    <StyledAssortiment>
      <ScrollToTop />
      <Box className="header">
        <Typography variant="h1">
          {dictionary.pages.assortiment.title}
        </Typography>
        <Box className="introduction">
          <Typography variant="h4">
            {renderText(dictionary.pages.assortiment.introduction)}
          </Typography>
        </Box>
      </Box>

      <Box className="cardWrapper">
        <Box className="imageWrapper top">
          <img src={wave_background} alt="wave background" loading="lazy" />
        </Box>
        <Box className="assortimentWrapper">
          {assortimentCards.map((assortimentCard) => (
            <AssortimentCard
              assortimentData={assortimentCard}
              background={assortimentCard.backgroundColor}
              selectedAssortiment={selectedAssortiment}
              onClick={(assortimentData) =>
                setSelectedAssortiment(assortimentData)
              }
              onClose={() => setSelectedAssortiment(undefined)}
            />
          ))}
        </Box>
        <MoreInfoCard selectedAssortiment={selectedAssortiment} />
      </Box>
      <Box className="workMethod">
        <Box className="imageWrapper">
          <img src={stepstones} alt="stepstones background" loading="lazy" />
        </Box>
        <Typography variant="h1">
          {renderText(dictionary.pages.assortiment.workmethod.title)}
        </Typography>
        <Box className="method method1">
          <Box className="image">
            <img src={stepstone} alt="stepstone" loading="lazy" />
          </Box>
          <Box className="content">
            <Typography variant="h2">
              {dictionary.pages.assortiment.workmethod.method1.title}
            </Typography>
            <Typography variant="h5">
              {renderText(dictionary.pages.assortiment.workmethod.method1.text)}
            </Typography>
          </Box>
        </Box>
        <Box className="method method2">
          <Box className="image">
            <img src={stepstone} alt="stepstone" loading="lazy" />
          </Box>
          <Box className="content">
            <Typography variant="h2">
              {dictionary.pages.assortiment.workmethod.method2.title}
            </Typography>
            <Typography variant="h5">
              {renderText(dictionary.pages.assortiment.workmethod.method2.text)}
            </Typography>
          </Box>
        </Box>
        <Box className="method method3">
          <Box className="image">
            <img src={stepstone} alt="stepstone" loading="lazy" />
          </Box>
          <Box className="content">
            <Typography variant="h2">
              {dictionary.pages.assortiment.workmethod.method3.title}
            </Typography>
            <Typography variant="h5">
              {renderText(dictionary.pages.assortiment.workmethod.method3.text)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StyledAssortiment>
  );
};
