import { createContext } from "react";
import nl_BE from "../language/Language.nl-BE.json";
import en_GB from "../language/Language.en-GB.json";

type LanguageDictionary<T = string | any> = {
  [key: string]: T;
};

export type LanguageCode = "nl_BE" | "en_GB";

export const dictionaryList: LanguageDictionary = {
  nl_BE,
  en_GB,
};

export const LanguageContext = createContext({
  language: "nl_BE",
  dictionary: dictionaryList["nl_BE"],
});
