import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledFooter = styled(Box)(({ theme }: StyledComponentProps) => ({
  position: "relative",
  marginTop: "3rem",
  ".footerWave": {
    position: "absolute",
    top: "0",
    height: "5rem",
    width: "100%",
    transform: "translateY(-50%)",
    zIndex: "-1",
  },
  ".header": {
    height: "30rem",
    background: theme.color.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingInline: "5rem",

    ".imageWrapper": {
      width: "20%",
      img: {
        height: "100%",
        width: "100%",
      },
    },
    ".infoWrapper": {
      height: "60%",
      display: "flex",
      flexDirection: "column",
      h2: {
        color: theme.color.background,
      },
      a: {
        textDecoration: "none",
      },
      ".divider": {
        width: "5rem",
        padding: "0",
        height: "1rem",
        display: "flex",
        alignItems: "center",
        img: {
          width: "100%",
          top: "0",
          height: "25%",
        },
      },
      ".listItem": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        paddingBlock: ".25rem",
        h4: {
          color: theme.color.background,
        },
      },
      ".link:hover": {
        h4: {
          color: theme.color.contrastColor,
          transition: ".5s",
        },

        cursor: "pointer",
      },
    },
  },
  ".copyright": {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.contrast.lighter,
  },

  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    ".header": {
      height: "fit-content",
      paddingInline: "3rem",
      gap: "1rem",
      paddingBottom: "5rem",
      boxSizing: "border-box",
      flexWrap: "wrap",
      ".imageWrapper": {
        width: "40%",
        height: "60%",
      },
      ".infoWrapper": {
        height: "60%",
        width: "40%",
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    ".header": {
      height: "fit-content",
      paddingInline: "2rem",
      gap: "2rem",
      paddingBottom: "2rem",
      boxSizing: "border-box",
      flexWrap: "no-wrap",
      flexDirection: "column",
      ".imageWrapper": {
        width: "80%",
      },
      ".infoWrapper": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    ".copyright": {
      textAlign: "center",
      h4: { fontSize: ".9rem" },
    },
  },
}));
