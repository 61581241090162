import { Box, Button, Typography } from "@mui/material";
import { StyledPricing } from "./Pricing.styled";
import { useContext } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { CurrencyEur, Timer } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { pricing } from "../../globals/constants";
import { ScrollToTop } from "../../components/ScrollToTop";

export const Pricing = () => {
  const { dictionary } = useContext(LanguageContext);
  const navigate = useNavigate();

  return (
    <StyledPricing>
      <ScrollToTop />
      <Typography variant="h1">{dictionary.pages.pricing.title}</Typography>
      <Box className="cardWrapper">
        <Box className="card">
          <Typography variant="h2">
            {dictionary.pages.pricing.cards.intake.title}
          </Typography>
          <Box className="time">
            <Timer size={30} weight="bold" color="#0D919F" />
            <Typography variant="h3">
              {dictionary.pages.pricing.cards.intake.time}
            </Typography>
          </Box>
          <Typography variant="h4">
            {dictionary.pages.pricing.cards.intake.description}
          </Typography>
          <Box className="price">
            <CurrencyEur size={30} weight="bold" color="#8AC219" />
            <Typography variant="h2">{pricing.intake}</Typography>
          </Box>
          <Button onClick={() => navigate("/contact")}>
            {dictionary.buttons.moreinfo}
          </Button>
        </Box>
        <Box className="card">
          <Typography variant="h2">
            {dictionary.pages.pricing.cards.followup.title}
          </Typography>
          <Box className="time">
            <Timer size={30} weight="bold" color="#0D919F" />
            <Typography variant="h3">
              {dictionary.pages.pricing.cards.followup.time}
            </Typography>
          </Box>
          <Typography variant="h4">
            {dictionary.pages.pricing.cards.followup.description}
          </Typography>
          <Box className="price">
            <CurrencyEur size={30} weight="bold" color="#8AC219" />
            <Typography variant="h2">{pricing.followup}</Typography>
          </Box>
          <Button onClick={() => navigate("/contact")}>
            {dictionary.buttons.moreinfo}
          </Button>
        </Box>
        <Box className="card">
          <Typography variant="h2">
            {dictionary.pages.pricing.cards.phonecall.title}
          </Typography>
          <Box className="time">
            <Timer size={30} weight="bold" color="#0D919F" />
            <Typography variant="h3">
              {dictionary.pages.pricing.cards.phonecall.time}
            </Typography>
          </Box>
          <Typography variant="h4">
            {dictionary.pages.pricing.cards.phonecall.description}
          </Typography>
          <Box className="price">
            <CurrencyEur size={30} weight="bold" color="#8AC219" />
            <Typography variant="h2">{pricing.phonecall}</Typography>
          </Box>
          <Button onClick={() => navigate("/contact")}>
            {dictionary.buttons.moreinfo}
          </Button>
        </Box>
      </Box>
    </StyledPricing>
  );
};
