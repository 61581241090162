import { Box, Typography, useTheme } from "@mui/material";
import { StyledDataCard } from "./DataCard.styled";
import { data } from "../../globals/constants";
import { Data } from "../../models/Types";

type DataCardProps = {
  dataCard: Data;
};

export const DataCard = ({ dataCard }: DataCardProps) => {
  return (
    <StyledDataCard
      isaddress={
        dataCard.value === data.address || dataCard.value === data.parking
      }
    >
      {dataCard.icon}
      <Box className="textWrapper">
        <Typography variant="h3">{dataCard.title}</Typography>
        <Box className="value">
          {dataCard.value.split("|").map((datacardValue) => (
            <Typography variant="h4">{datacardValue}</Typography>
          ))}
        </Box>
      </Box>
    </StyledDataCard>
  );
};
