import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledInfo = styled(Box)(({ theme }: StyledComponentProps) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  paddingTop: "2rem",
  paddingBottom: "7.5rem",
  h1: {
    color: theme.color.main,
    textAlign: "center",
  },
  h4: {
    minHeight: "20rem",
    width: "65rem",
    paddingBlock: "2rem",
  },
  h3: {
    fontWeight: "bold",
  },
  a: {
    color: theme.color.contrastColor,
    fontWeight: "bold",
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    gap: "0rem",
    paddingTop: "3rem",
    paddingBottom: "3rem",
    h1: {
      fontSize: "2.5rem",
      padding: "0rem",
      paddingTop: "3rem",
      lineHeight: "50px",
    },
    h4: {
      width: "90%",
      padding: "1rem",
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    paddingTop: "2rem",
    h1: {
      height: "5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));
