export const pricing = {
  intake: 40,
  followup: 55,
  phonecall: 0,
};

export const formPlaceholders = {
  firstName: "Jan",
  lastName: "Janssens",
  email: "jan.janssens@mail.com",
  password: "*********",
};

export const data = {
  phoneNr: "+32 494/99.39.71",
  email: "info@de-cameleon.be",
  address: "Basilieklaan 10 3270 Scherpenheuvel",
  instagram: "@de.cameleon.2024",
  vat: "BE 0756.838.144",
  parking:
    "[P1] Parking Noordervest ~2min (Parkeerkaart) | [P2] Parking Mariahal ~8min (Gratis) | [P3] Parking Stadhuis ~4min (Parkeerkaart)",
};

export const SERVERURL = "https://backend.de-cameleon.be/api/v1";
export const GOOGLE_MAPS_API_KEY = "AIzaSyCdRJVO5nGND-fjtlyaTfaFxNydvkIeYco";
export const GOOGLE_MAP_ID = "8120954a2a3d0c4 ";
