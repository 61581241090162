import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type ScrollToTopProps = {
  offset?: number;
};

export const ScrollToTop = ({ offset }: ScrollToTopProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, offset ? offset : 0);
  }, [pathname]);

  return null;
};
