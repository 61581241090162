import { Box, Typography } from "@mui/material";
import { StyledHome } from "./Home.styled";
import { LanguageContext } from "../../globals/LanguageContext";
import { useContext } from "react";
import homeBackground from "../../images/Home/background.jpg";
import wave from "../../images/Home/wave.background.v3.png";
import cameleonIcon from "../../images/cameleonIcon.v3.png";
import { HomeCard } from "../../components/homeCard/HomeCard";
import { Palette } from "@phosphor-icons/react/dist/ssr";
import { Footprints, PuzzlePiece } from "@phosphor-icons/react";
import { CustomIcon } from "../../components/customIcon/CustomIcon";
import { ScrollToTop } from "../../components/ScrollToTop";

const cards = [
  {
    icon: <CustomIcon size={90} src={cameleonIcon} color="#8AC219" />,
    title: "",
    text: "Veel mensen met autisme ervaren dat ze zich voortdurend moeten aanpassen of camoufleren om aan de verwachtingen van hun omgeving te voldoen. Dit kan op lange termijn vermoeiend en stressvol zijn. In mijn praktijk bied ik een veilige ruimte om jezelf beter te begrijpen, je sterktes te erkennen en strategieën te vinden die bij jou passen, zodat je meer in balans kunt leven zonder voortdurend te moeten maskeren.",
  },
  {
    icon: <Palette size={75} color="#C219A3" />,
    title: "",
    text: "De verschillende kleuren van de-Cameleon verwijzen naar het individu, elk met zijn eigen karakter, zijn eigen omgeving, zijn eigen kunnen, zijn eigen ‘kleur’. Bij de coaching probeer ik deze eigenheid zo goed mogelijk mee te nemen en de coaching zo veel mogelijk te richten naar het individu.",
  },
  {
    icon: <PuzzlePiece size={75} color="#11B7CA" />,
    title: "",
    text: "De puzzelstukjes representeren dat iemand met autisme puzzeltijd nodig heeft. En dat langzaam maar zeker alles op zijn plaats valt. Samen zoeken we het puzzelstukje dat ontbreekt waardoor jij vastloopt. Als je ontdekt dat je autisme hebt, kan ik jou helpen zodat steeds meer puzzelstukjes op hun plek vallen.’",
  },
  {
    icon: <Footprints size={75} color="#2E2E2E" />,
    title: "Stapsteen",
    text: "verwijst naar een steunpunt onderweg, een vaste plek in een rivierbedding waarop je even kunt rusten voordat je verder gaat. In mijn praktijk bied ik ondersteuning aan mensen met autisme die op zoek zijn naar meer evenwicht, zelfvertrouwen en welzijn in het dagelijks leven. Samen werken we stap voor stap aan concrete doelen, zodat je je sterker voelt en meer grip krijgt op je eigen pad. Mijn begeleiding is afgestemd op jouw specifieke behoeften, met als doel een leven dat beter aansluit bij wie jij bent en wat jij nodig hebt.",
  },
];

export const Home = () => {
  const { dictionary } = useContext(LanguageContext);

  const img = new Image();
  img.src = "../../images/Home/background.jpg";

  return (
    <StyledHome>
      <ScrollToTop />
      <Box className="background">
        <img
          src={homeBackground}
          alt="background paitning of Bijnens A.rt"
          loading="lazy"
        />
      </Box>
      <Box className="backgroundOverlay" />
      <Box className="header">
        <Typography variant="h1">De-Cameleon</Typography>
      </Box>
      <Box className="divider">
        <img src={wave} alt="wave" loading="lazy" />
        <Typography variant="h2">
          {dictionary.pages.home.description}
        </Typography>
      </Box>
      <Typography variant="h4" className="cameleonText">
        De-Cameleon is een coaching praktijk die zich richt op het begeleiden en
        ondersteunen van kinderen, jongeren en volwassenen met (een vermoeden
        van) autisme en hun omgeving (ouders, familie, vrienden, school,…).
      </Typography>
      <Box className="cardWrapper">
        {cards.map((card) => (
          <HomeCard homeCard={card} />
        ))}
      </Box>
    </StyledHome>
  );
};
