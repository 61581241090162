import { Box, keyframes, styled } from "@mui/material";

interface StyledAssortimentCardProps {
  background: string;
  selectedAssortimentIndex?: number;
  currentAssortimentIndex: number;
}

export const StyledAssortimentCard = styled(Box)<StyledAssortimentCardProps>(
  ({
    theme,
    background,
    selectedAssortimentIndex,
    currentAssortimentIndex,
  }) => ({
    height: "27rem",
    width: "25%",
    background: background,
    paddingBlock: "5rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    animation: selectedAssortimentIndex
      ? selectedAssortimentIndex === currentAssortimentIndex
        ? `${move(
            currentAssortimentIndex,
            selectedAssortimentIndex
          )} 1s ease-in-out`
        : `${move(
            currentAssortimentIndex,
            selectedAssortimentIndex
          )} 1s ease-in-out`
      : `${easeIn(currentAssortimentIndex)} 1s ease-in-out`,
    zIndex: `${
      selectedAssortimentIndex
        ? 4 - currentAssortimentIndex + 1
        : currentAssortimentIndex
    }`,
    animationFillMode: "forwards",
    svg: {
      color: theme.color.background,
      paddingBottom: "2rem",
    },
    "h2, h3": {
      color: theme.color.background,
      textAlign: "center",
      minHeight: "32px",
    },
    h4: {
      width: "60%",
      color: theme.color.background,
      textAlign: "center",
      paddingBlock: "2rem",
    },
    button: {
      position: "absolute",
      background: theme.color.contrastColor,
      bottom: "3rem",
      "&:hover": {
        background: theme.color.light,
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      height: selectedAssortimentIndex ? "10rem" : "27rem",
      minWidth: "100%",
      paddingBlock: "2rem",
      paddingTop: "5rem",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      gap: "1rem",
      svg: {
        height: "5rem",
        width: "10rem",
        padding: "0",
      },
      "h3, h4": {
        display: selectedAssortimentIndex ? "none" : "initial",
      },
      h4: {
        width: "80%",
      },
      button: {
        bottom: selectedAssortimentIndex ? ".5rem" : "1rem",
      },
      animationFillMode: "forwards",
      animation: selectedAssortimentIndex
        ? `${moveUp(
            currentAssortimentIndex,
            selectedAssortimentIndex
          )} 1s ease-in-out forwards`
        : `${easeDown(currentAssortimentIndex)} 1s ease-in-out`,
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
      height: selectedAssortimentIndex ? "5rem" : "27rem",
      "h2, h3, h4": {
        display: selectedAssortimentIndex ? "none" : "initial",
      },
      svg: {
        height: "4rem",
      },
    },
  })
);

const move = (index: number, selectedAssortimentIndex: number) => keyframes`
  0% {
    transform: translateX(0);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateX(-${index * 100}%);
    ${index > selectedAssortimentIndex && "opacity: 0"}  
    ${
      index === selectedAssortimentIndex &&
      `transform: translateX(-${(index - 1) * 100}%);`
    }  
  }
`;

const easeIn = (index: number) => keyframes`
  0% {
  ${index === 1 && `transform: translateX(-${index * 100}%)`}
  ${index > 1 && `transform: translateX(-${(index - 1) * 100}%)`}
  }
  100% {
    transform: translateX(0);
  }
`;

const moveUp = (index: number, selectedAssortimentIndex: number) => keyframes`
  0% {
    transform: translateY(0);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateY(-${index * 100}%);
    ${index > selectedAssortimentIndex ? "opacity: 0;" : ""}
    ${
      index === selectedAssortimentIndex
        ? `transform: translateY(-${(index - 1) * 100}%);`
        : ""
    }
  }
`;

const easeDown = (index: number) => keyframes`
  0% {
  ${index === 1 && `transform: translateY(-${index * 100}%)`}
  ${index > 1 && `transform: translateY(-${(index - 1) * 100}%)`}
  }
  100% {
    transform: translateY(0);
  }
`;
