import { Box, Slide } from "@mui/material";
import { StyledNavbar } from "./navbar.styled";
import logonavbar from "../../images/decameleon_navbar.png";
import { useNavLinks } from "../../hooks/useNavLinks";
import { NavLinkComponent } from "./navlink/navlink";
import { useState } from "react";
import { List } from "@phosphor-icons/react";

export const Navbar = () => {
  const { navLinks } = useNavLinks();
  const [navMenuOpened, setNavMenuOpened] = useState<boolean>(false);

  return (
    <StyledNavbar>
      <Box className="shape" />
      <Box className="navbar">
        <Box className="logoWrapper">
          <img src={logonavbar} alt="de-cameleon logo navbar" loading="lazy" />
        </Box>
        <Box className="navlinks">
          {navLinks.map((navlink) => (
            <NavLinkComponent navlink={navlink} />
          ))}
        </Box>
        <List
          size={48}
          weight="bold"
          className="menuIcon"
          onClick={() => {
            setNavMenuOpened(!navMenuOpened);
          }}
        />
      </Box>
      <Slide in={navMenuOpened} direction="down">
        <Box className="navMenu">
          <Box className="navlinks">
            {navLinks.map((navlink, index) => (
              <NavLinkComponent navlink={navlink} key={index} />
            ))}
          </Box>
          <Box className="shape" />
        </Box>
      </Slide>
    </StyledNavbar>
  );
};
