import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Home } from "./pages/home/Home";
import {
  dictionaryList,
  LanguageCode,
  LanguageContext,
} from "./globals/LanguageContext";
import { useState } from "react";
import { theme } from "./globals/theme";
import { Navbar } from "./components/navbar/navbar";
import { Assortiment } from "./pages/assortiment/Assortiment";
import { Footer } from "./components/footer/Footer";
import { AboutUs } from "./pages/aboutus/AboutUs";
import { Pricing } from "./pages/pricing/Pricing";
import { Contact } from "./pages/contact/Contact";
import { Info } from "./pages/info/Info";
export const App = () => {
  //eslint-disable-next-line
  const [language, setLanguage] = useState<LanguageCode>("nl_BE");

  return (
    <>
      <LanguageContext.Provider
        value={{
          language,
          dictionary: dictionaryList[language],
        }}
      >
        <ThemeProvider theme={theme}>
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/assortiment" element={<Assortiment />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/info/:page" element={<Info />} />
              <Route path="*" element={<></>} />
            </Routes>
            <Footer />
          </Router>
        </ThemeProvider>
      </LanguageContext.Provider>
    </>
  );
};
