import { Box, styled } from "@mui/material";

interface StyledMoreInfoCardProps {
  background?: string;
}

export const StyledMoreInfoCard = styled(Box)<StyledMoreInfoCardProps>(
  ({ theme, background = "#0D919F" }) => ({
    width: "100%",
    height: "100%",
    background: background,
    position: "absolute",
    zIndex: "-5",
    paddingLeft: "25%",
    top: "0",
    ".content": {
      display: "flex",
      gap: "3%",
      alignItems: "center",
      height: "100%",
      paddingBottom: "2rem",
      boxSizing: "border-box",
      ".section": {
        position: "relative",
        display: "flex",
        alignItems: "center",
      },
      ".section1": { width: "20%" },
      ".section2": { width: "20%" },
      ".section3": { width: "25%" },
      h5: {
        color: theme.color.background,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
      ul: {
        display: "flex",
        flexDirection: "column",
        gap: ".25rem",
        paddingLeft: "2rem",
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      padding: "0",
      paddingTop: "20rem",
      ".content": {
        flexDirection: "column",
        width: "90%",
        textAlign: "center",
        gap: "1rem",
        margin: "auto",
        ".section": {
          width: "100%",
        },
        ul: {
          gap: ".25rem",
          paddingLeft: ".5rem",
          li: {
            textAlign: "left",
          },
        },
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
      paddingTop: "12.7rem",
    },
  })
);
