import { Box, styled } from "@mui/material";

type StyledCustomIconProps = {
  size: number;
  color: string;
};

export const StyledCustomIcon = styled(Box)(
  ({ size, color }: StyledCustomIconProps) => ({
    width: `${size}px`,
    height: `${size}px`,
    color: color,
    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  })
);
