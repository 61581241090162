import { Box, Typography, useTheme } from "@mui/material";
import { StyledMessagePoppup } from "./MessagePoppup.styled";
import { useEffect, useState } from "react";
import { useTextRenderer } from "../../hooks/useTextrenderer";

type MessagePoppupProps = {
  text: string[];
  statusCode: number;
  closeMessage: () => void;
};

export const MessagePoppup = ({
  text,
  statusCode,
  closeMessage,
}: MessagePoppupProps) => {
  const theme = useTheme();
  const [background, setBackground] = useState<string>(
    theme.palette.success.main
  );
  const [responseText, setResponseText] = useState<string>("");
  const { renderText } = useTextRenderer();

  useEffect(() => {
    switch (statusCode) {
      case 201: {
        setBackground(theme.palette.success.main);
        setResponseText(text[0]);
        break;
      }
      default: {
        setBackground(theme.palette.error.main);
        setResponseText(text[1]);
      }
    }

    setTimeout(() => {
      closeMessage();
    }, 10 * 1000);
  }, []);

  return (
    <StyledMessagePoppup background={background}>
      <Box className="content">
        <Typography>{renderText(responseText)}</Typography>
      </Box>
    </StyledMessagePoppup>
  );
};
