import { useContext, useEffect, useMemo, useState } from "react";
import { ContactForm } from "../../components/contactForm/ContactForm";
import { Message } from "../../models/Message";
import { StyledContact } from "./Contact.styled";
import { useMessage } from "../../stores/useMessage";
import { Box, Typography } from "@mui/material";
import { Map } from "../../components/googleMap/GoogleMap";
import { useDataCards } from "../../hooks/useDataCards";
import { DataCard } from "../../components/dataCard/DataCard";
import { LanguageContext } from "../../globals/LanguageContext";
import { ScrollToTop } from "../../components/ScrollToTop";
import { MessagePoppup } from "../../components/messagePoppup/MessagePoppup";

export const Contact = () => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>(0);
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(true);
  const { addMessage } = useMessage();
  const { dataCards } = useDataCards(50);
  const { dictionary } = useContext(LanguageContext);

  const sendMessage = async (message: Message) => {
    const newMessage: Message = {
      ...message,
      message: message.message.replaceAll("\n", "[/breakline]"),
    };

    const status = await addMessage(newMessage);
    setStatusCode(status);
    setShowMessage(true);
  };

  useEffect(() => {
    setTriggerUpdate(true);
    setTimeout(() => {
      setTriggerUpdate(false);
    }, 1000);
  }, [showMessage]);

  useMemo(() => {
    setTriggerUpdate(false);
  }, []);

  return (
    <StyledContact>
      {triggerUpdate && <ScrollToTop />}
      {showMessage && (
        <MessagePoppup
          text={[
            dictionary.messages.poppup.success,
            dictionary.messages.poppup.error,
          ]}
          statusCode={statusCode}
          closeMessage={() => setShowMessage(false)}
        />
      )}

      <Typography variant="h1">{dictionary.pages.contact.title}</Typography>
      <Typography variant="h5" className="description">
        {dictionary.pages.contact.description}
      </Typography>
      <Box className="row">
        <ContactForm onSend={(message) => sendMessage(message)} />
        <Box className="info">
          <Typography variant="h2">Gegevens</Typography>
          <Box className="content">
            {dataCards.map((datacard) => (
              <DataCard dataCard={datacard} />
            ))}
          </Box>
        </Box>
      </Box>
      <Box className="row">
        <Box className="map">
          <Map />
        </Box>
      </Box>
    </StyledContact>
  );
};
