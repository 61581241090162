import { Controller, useForm } from "react-hook-form";
import { StyledContactForm } from "./ContactForm.styled";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { formPlaceholders } from "../../globals/constants";
import { Message } from "../../models/Message";

type ContactFormProps = {
  onSend: (message: Message) => void;
};

export const ContactForm = ({ onSend }: ContactFormProps) => {
  const { dictionary } = useContext(LanguageContext);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Message>();

  const onSubmit = (data: Message) => {
    onSend(data);
    reset();
  };

  return (
    <StyledContactForm onSubmit={handleSubmit(onSubmit)}>
      <Box className="contactformrow">
        <Typography variant="h4">
          Voornaam<span>*</span>
        </Typography>
        <TextField
          {...register("firstName", {
            required: dictionary.messages.error.form.fieldrequired,
          })}
          placeholder={formPlaceholders.firstName}
          error={!!errors.firstName}
          helperText={errors?.firstName?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="contactformrow">
        <Typography variant="h4">
          Achternaam<span>*</span>
        </Typography>
        <TextField
          {...register("lastName", {
            required: dictionary.messages.error.form.fieldrequired,
          })}
          placeholder={formPlaceholders.lastName}
          error={!!errors.lastName}
          helperText={errors?.lastName?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="contactformrow">
        <Typography variant="h4">
          Email<span>*</span>
        </Typography>
        <TextField
          {...register("email", {
            required: dictionary.messages.error.form.fieldrequired,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: dictionary.messages.error.form.wrongemail,
            },
          })}
          placeholder={formPlaceholders.email}
          error={!!errors.email}
          helperText={errors?.email?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="contactformrow">
        <Typography variant="h4">
          Onderwerp<span>*</span>
        </Typography>
        <TextField
          {...register("subject", {
            required: dictionary.messages.error.form.fieldrequired,
          })}
          placeholder={dictionary.placeholders.contactsubject}
          error={!!errors.subject}
          helperText={errors?.subject?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="contactformrow message">
        <Typography variant="h4">
          Uw Bericht<span>*</span>
        </Typography>
        <TextField
          {...register("message", {
            required: dictionary.messages.error.form.fieldrequired,
          })}
          placeholder={dictionary.placeholders.contactmessage}
          error={!!errors.message}
          helperText={errors?.message?.message}
          InputLabelProps={{ shrink: true }}
          multiline
          InputProps={{
            rows: 5,
          }}
        />
      </Box>

      <Button type="submit">{dictionary.buttons.send}</Button>
    </StyledContactForm>
  );
};
