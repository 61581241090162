import axios, { AxiosResponse } from "axios";
import { SERVERURL } from "../globals/constants";
import { Message } from "../models/Message";

export const createMessage = async (email: Message): Promise<number> => {
  try {
    const response: AxiosResponse = await axios.post<Message>(
      `${SERVERURL}/mail/create`,
      email,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
