import { StyledCustomIcon } from "./CustomIcon.styled";

type CustomIconProps = {
  src: string;
  size: number;
  color: string;
};

export const CustomIcon = ({ src, size, color }: CustomIconProps) => {
  return (
    <StyledCustomIcon size={size} color={color}>
      <img src={src} alt="customIcon" />
    </StyledCustomIcon>
  );
};
