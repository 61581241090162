import { useNavigate, useParams } from "react-router-dom";
import { useContext, useMemo, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { useTextRenderer } from "../../hooks/useTextrenderer";
import { ScrollToTop } from "../../components/ScrollToTop";
import { Button, Typography } from "@mui/material";
import { StyledInfo } from "./Info.styled";

export const Info = () => {
  const { page } = useParams();
  const { dictionary } = useContext(LanguageContext);
  const { renderText } = useTextRenderer();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");

  useMemo(() => {
    switch (page) {
      case "privacy":
        setTitle(dictionary.info.privacy.title);
        setBody(dictionary.info.privacy.body);
        break;
      case "cookies":
        setTitle(dictionary.info.cookies.title);
        setBody(dictionary.info.cookies.body);
        break;
      case "conditions":
        setTitle(dictionary.info.conditions.title);
        setBody(dictionary.info.conditions.body);
        break;
    }
  }, [page, dictionary]);

  return (
    <StyledInfo>
      <ScrollToTop />
      <Typography variant="h1">{title}</Typography>
      <Typography variant="h4">{renderText(body)}</Typography>
      <Button variant="outlined" onClick={() => navigate("/")}>
        {dictionary.buttons.goback}
      </Button>
    </StyledInfo>
  );
};
