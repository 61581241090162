import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledPricing = styled(Box)(({ theme }: StyledComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  h1: {
    color: theme.color.dark,
    paddingBlock: "2rem",
  },
  ".cardWrapper": {
    display: "flex",
    paddingBlock: "2rem",
    gap: "2rem",
    flexWrap: "wrap",
    ".card": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      alignItems: "center",
      justifyContent: "space-between",
      width: "25rem",
      boxShadow: `0px 0px 3px 3px ${theme.contrast.lighter}`,
      borderRadius: "20px",
      padding: "3rem",
      boxSizing: "border-box",
      h2: {
        color: theme.color.dark,
        textAlign: "center",
      },
      h3: {
        color: theme.color.main,
      },
      h4: {
        textAlign: "center",
      },
      ".time, .price": {
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        h2: {
          color: theme.color.contrastColor,
        },
      },
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    paddingTop: "5rem",
    ".cardWrapper": {
      paddingBlock: "1rem",
      gap: "2rem",
      flexWrap: "wrap",
      justifyContent: "center",
      ".card": {
        width: "20rem",
        padding: "2rem 1rem",
      },
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    paddingTop: "2rem",
    ".cardWrapper": {
      paddingBlock: "0",
      paddingBottom: "3rem",
      gap: "2rem",
      flexWrap: "wrap",
      justifyContent: "center",
      ".card": {
        width: "20rem",
        padding: "2rem 1rem",
      },
    },
  },
}));
