import { createTheme } from "@mui/material";
import { contrastColors, defaultColors, themeColors } from "./themeColors";
import { StyledComponentProps } from "../models/Types";

declare module "@mui/material/styles" {
  interface Theme {
    color: {
      dark: string;
      main: string;
      lighter: string;
      light: string;
      textColor: string;
      contrastColor: string;
      background: string;
    };
    contrast: {
      dark: string;
      main: string;
      lighter: string;
      light: string;
      shadowColor: string;
    };
  }
  interface ThemeOptions {
    color?: {
      dark?: string;
      main?: string;
      lighter?: string;
      light?: string;
      textColor?: string;
      contrastColor?: string;
    };
    contrast?: {
      dark?: string;
      main?: string;
      lighter?: string;
      light?: string;
      shadowColor?: string;
    };
  }
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
  }
}

const defaultTheme = {
  breakpoints: {
    values: {
      mobile: 640,
      tablet: 1200,
    },
  },
  palette: {
    error: {
      main: defaultColors.red,
    },
    success: {
      main: defaultColors.green,
    },
    warning: {
      main: defaultColors.orange,
    },
  },
  typography: {
    h1: {
      fontFamily: "'Product Sans', sans-serif",
      fontSize: "64px",
      fontWeight: "600",
      lineHeight: "72px",
      letterSpacing: "1px",
      [`@media screen and (max-width: 640px)`]: {
        fontSize: "2.5rem",
      },
    },
    h2: {
      fontFamily: "'Product Sans', sans-serif",
      fontSize: "32px",
      fontWeight: "600",
      lineHeight: "40px",
    },
    h3: {
      fontFamily: "'Product Sans', sans-serif",
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "32px",
      letterSpacing: "1px",
    },
    h4: {
      fontFamily: "'Product Sans', sans-serif",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "27px",
    },
    h5: {
      fontFamily: "'Product Sans', sans-serif",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    h6: {
      fontFamily: "'Product Sans', sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "21px",
    },
    body1: {
      fontFamily: "'Product Sans', sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "19px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }: StyledComponentProps) => ({
          padding: "0.5rem 2rem",
          fontFamily: "'Product Sans', sans-serif",
          fontWeight: "bold",
          background: theme.color.main,
          color: theme.color.background,
          borderRadius: "10px",
          transition: ".5s",
          width: "fit-content",
          "&:hover": {
            background: theme.color.contrastColor,
          },
          "&:active": {},
          "&:focus": {},
          "&:focus-visible": {},
        }),
        outlined: ({ theme }: StyledComponentProps) => ({
          padding: "0.5rem 2rem",
          fontFamily: "'Product Sans', sans-serif",
          fontWeight: "bold",
          background: "none",
          color: theme.color.main,
          border: `2px solid ${theme.color.main}`,
          borderRadius: "10px",
          transition: ".5s",
          width: "fit-content",
          "&:hover": {
            background: theme.color.main,
            border: `2px solid ${theme.color.main}`,
            color: theme.color.background,
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }: StyledComponentProps) => ({
          ".MuiInputBase-root": {
            color: theme.color.contrastColor,
            margin: "0",
            input: {
              padding: ".5rem 1rem",
            },
          },
          ".MuiFormLabel-root": {
            display: "none",
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }: StyledComponentProps) => ({
          background: theme.contrast.lighter,
          color: theme.color.dark,
          fontWeight: "bold",
          fontSize: "1rem",
          padding: "0",
          "&.MuiInputBase-multiline": {
            padding: ".5rem 1rem",
          },
        }),
        notchedOutline: {
          border: "none",
        },
      },
    },
  },
};

export const theme = createTheme(
  {
    color: themeColors,
    contrast: contrastColors,
  },
  defaultTheme
);
