import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledAssortiment = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    position: "relative",
    overflow: "hidden",
    ".header": {
      h1: {
        color: theme.color.dark,
        textAlign: "center",
        paddingTop: "2rem",
      },
      ".introduction": {
        width: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        paddingBlock: "1rem",
        h4: {
          width: "60%",
          textAlign: "center",
          margin: "auto",
          boxSizing: "border-box",
          paddingBlock: "1rem",
          color: theme.color.dark,
        },
      },
    },
    ".cardWrapper": {
      overflow: "hidden",
      position: "relative",
      ".imageWrapper": {
        height: "15rem",
        position: "absolute",
        width: "100%",
        zIndex: "6",
        img: {
          width: "100%",
          height: "100%",
        },
      },
      ".top": {
        top: "-13.5rem",
      },
      ".assortimentWrapper": {
        display: "flex",
        ".card": {
          background: "#10ACBD",
        },
      },
    },
    ".workMethod": {
      marginTop: "5rem",
      height: "55rem",
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      ".imageWrapper": {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        paddingBlock: "5rem",
        img: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      },
      h1: {
        position: "absolute",
        width: "100%",
        color: theme.color.dark,
        top: "-2rem",
        span: {
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        },
      },
      ".method": {
        height: "55%",
        width: "60%",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.color.dark,
        ".image": {
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: "-1",
          top: "0",
          img: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
          },
        },
        ".content": {
          width: "50%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
          h6: {
            color: theme.contrast.dark,
            fontSize: ".75rem",
            margin: "0",
            lineHeight: "1rem",
          },
        },
      },
      ".method1": {
        ".content": {
          padding: "0 1rem 0 0rem",
        },
      },
      ".method2": {
        transform: "rotate(-40deg)",
        alignSelf: "flex-end",
        marginLeft: "5rem",
        height: "50%",
        width: "60%",
        ".content": {
          transform: "rotate(40deg)",
          padding: "0 1rem 2rem 0",
        },
      },
      ".method3": {
        transform: "rotate(-25deg)",
        ".content": {
          transform: "rotate(25deg)",
          padding: "0 1rem 0rem 0",
          width: "50%",
        },
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      ".header": {
        h1: {
          paddingTop: "5rem",
        },
        ".introduction": {
          paddingBlock: "0rem",
          paddingBottom: "1rem",
          h4: {
            width: "90%",
          },
        },
      },
      ".cardWrapper": {
        minHeight: "60rem",
        ".imageWrapper": {
          height: "5rem",
        },
        ".top": {
          top: "-2rem",
        },
        ".assortimentWrapper": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      },
      ".workMethod": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "0",
        margin: "0",
        height: "fit-content",
        gap: "2rem",
        ".imageWrapper": {
          display: "none",
        },
        h1: {
          position: "relative",
          width: "100%",
          color: theme.color.dark,
          textAlign: "center",
          paddingBlock: "2rem",
          top: "0rem",
        },
        ".method": {
          minHeight: "30rem",
          width: "100%",
          paddign: "0",
          ".image": {
            width: "150%",
          },
          ".content": {
            width: "40%",
          },
        },
        ".method1": {
          ".content": {
            paddingBottom: "1rem",
          },
        },
        ".method2": {
          transform: "rotate(-55deg)",
          alignSelf: "flex-end",
          marginLeft: "5rem",
          ".content": {
            transform: "rotate(55deg)",
            padding: "0 2rem 0 0",
          },
        },
        ".method3": {
          transform: "rotate(-25deg)",
          ".content": {
            transform: "rotate(25deg)",
            padding: "0 2rem 0rem 0",
          },
        },
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
      ".cardWrapper": { minHeight: "80rem" },
      ".method": {
        ".content": {
          minWidth: "80%",
        },
      },
    },
  })
);
