import { Box, Fade, Typography } from "@mui/material";
import { AssortimentData } from "../../models/Types";
import { StyledMoreInfoCard } from "./MoreInfoCard.styled";
import { useTextRenderer } from "../../hooks/useTextrenderer";
import { Dispatch, useEffect, useRef, useState } from "react";

type MoreInfoCardProps = {
  selectedAssortiment?: AssortimentData;
};

export const MoreInfoCard = ({ selectedAssortiment }: MoreInfoCardProps) => {
  const { renderText } = useTextRenderer();

  const [data, setData] = useState<AssortimentData | undefined>(
    selectedAssortiment
  );
  const [isVisible, setIsVisible] = useState(true);

  const handleToggle = () => {
    if (isVisible) {
      setIsVisible(false);
      setTimeout(() => setData(undefined), 500);
    } else {
      setData(selectedAssortiment);
      setIsVisible(true);
    }
  };

  useEffect(() => {
    handleToggle();
    //eslint-disable-next-line
  }, [selectedAssortiment]);
  return (
    <StyledMoreInfoCard background={selectedAssortiment?.backgroundColor}>
      <Fade in={isVisible} timeout={1000}>
        <Box className="content">
          <Box className="section section1">
            {data && (
              <Typography variant="h5">
                {renderText(data?.info.section1)}
              </Typography>
            )}
          </Box>
          <Box className="section section2">
            {data && (
              <Typography variant="h5">
                {renderText(data.info.section2)}
              </Typography>
            )}
          </Box>
          <Box className="section section3">
            {data && (
              <Typography variant="h5">
                {renderText(data.info.section3)}
              </Typography>
            )}
          </Box>
        </Box>
      </Fade>
    </StyledMoreInfoCard>
  );
};
