import { Box, Typography } from "@mui/material";
import { StyledHomeCard } from "./HomeCard.styled";
import wave_background from "../../images/Assortiments/wave_background.png";
import wave_background_lr from "../../images/Assortiments/wave_background_lr.png";
import { HomeCardData } from "../../models/Types";
import { useTextRenderer } from "../../hooks/useTextrenderer";

type HomeCardProps = {
  homeCard: HomeCardData;
};

export const HomeCard = ({ homeCard }: HomeCardProps) => {
  const { renderText } = useTextRenderer();

  return (
    <StyledHomeCard>
      <Box className="wave top">
        <img src={wave_background} alt="wave background" />
      </Box>
      <Box className="wave right">
        <img src={wave_background_lr} alt="wave background vertical" />
      </Box>
      <Box className="wave bottom">
        <img src={wave_background} alt="wave background" />
      </Box>
      <Box className="wave left">
        <img src={wave_background_lr} alt="wave background vertical" />
      </Box>
      <Box className="content">
        <Box>{homeCard.icon}</Box>
        <Typography variant="h3">{homeCard.title}</Typography>
        <Typography variant="h4">{renderText(homeCard.text)}</Typography>
      </Box>
    </StyledHomeCard>
  );
};
