import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledHome = styled(Box)(({ theme }: StyledComponentProps) => ({
  "@font-face": {
    fontFamily: "Composition",
    src: "url('/fonts/composition.regular.ttf') format('truetype')",
  },
  ".background": {
    width: "100%",
    zIndex: "-2",
    position: "absolute",
    top: "0",
    height: "90%",
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  ".backgroundOverlay": {
    position: "absolute",
    zIndex: "-1",
    top: "0",
    background: "rgba(33, 33, 33, 0.4)",
    width: "100%",
    height: "90%",
  },
  ".header": {
    height: "37.5rem",
    color: theme.color.background,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    h1: {
      textAlign: "center",
      fontSize: "5.2rem",
      textShadow: `2px 2px 4px ${theme.color.dark}`,
      fontFamily: "'Composition', sans-serif",
    },
  },
  ".divider": {
    width: "100%",
    height: "15rem",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    img: {
      zIndex: "-1",
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    h2: {
      width: "40%",
      textAlign: "center",
      padding: "3rem",
      color: theme.color.dark,
    },
  },
  ".cardWrapper": {
    display: "flex",
    justifyContent: "center",
    gap: "3rem",
    paddingBottom: "5rem",
  },

  ".cameleonText": {
    textAlign: "center",
    width: "50%",
    margin: "auto",
    paddingBlock: "2rem",
    fontWeight: "bold",
    color: theme.color.dark,
  },

  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    ".background": {
      height: "45rem",
    },
    ".backgroundOverlay": {
      height: "45rem",
    },
    ".header": {
      height: "35rem",
    },
    ".divider": {
      height: "10rem",
      position: "relative",
      h2: {
        width: "70%",
        fontSize: "1.5rem",
        padding: "0",
      },
    },
    ".cardWrapper": {
      flexWrap: "wrap",
      gap: "1rem",
    },
    ".cameleonText": {
      width: "80%",
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    ".background": {
      height: "40rem",
    },
    ".backgroundOverlay": {
      height: "40rem",
    },
    ".header": {
      height: "30rem",
      h1: {
        fontSize: "3rem",
      },
    },
    ".divider": {
      height: "7.5rem",
      position: "relative",
      h2: {
        width: "80%",
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
    },
    ".cardWrapper": {
      flexWrap: "wrap",
      gap: "1rem",
    },
    ".cameleonText": {
      width: "80%",
      fontSize: ".8rem",
      lineHeight: "1.2rem",
    },
  },
}));
