import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

interface StyledMessagePoppupProps {
  background: string;
}

export const StyledMessagePoppup = styled(Box)<StyledMessagePoppupProps>(
  ({ theme, background }) => ({
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    top: "15rem",
    left: "0",
    ".content": {
      width: "fit-content",
      maxWidth: "40%",
      boxShadow: `0px 0px 10px 10px ${theme.contrast.shadowColor}`,
      background: background,
      color: theme.color.background,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      padding: "1rem 5rem",
      borderRadius: "10px",
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      top: "15rem",
      ".content": {
        padding: "1rem 2rem",
        maxWidth: "95%",
        boxSizing: "border-box",
      },
    },
  })
);
