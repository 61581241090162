import { Box, Button, Collapse, Typography } from "@mui/material";
import { StyledAboutUs } from "./AboutUs.styled";
import { useContext, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import backgroundlinetop from "../../images/AboutUs/background.line.top.v1.png";
import backgroundline from "../../images/AboutUs/background.line.v3.png";
import image1 from "../../images/AboutUs/image.1.v3.png";
import image2 from "../../images/AboutUs/image.2.v3.png";
import image3 from "../../images/AboutUs/image.3.v4.png";
import image4 from "../../images/AboutUs/image.4.v2.png";
import image5 from "../../images/AboutUs/image.5.v2.png";
import { CaretCircleUp, SmileyWink } from "@phosphor-icons/react";
import { ScrollToTop } from "../../components/ScrollToTop";
import { CaretCircleDown } from "@phosphor-icons/react/dist/ssr";
import { Link, Element } from "react-scroll";

export const AboutUs = () => {
  const { dictionary } = useContext(LanguageContext);
  const [extended, setExtended] = useState<boolean>(false);

  return (
    <StyledAboutUs>
      <ScrollToTop />

      <Typography variant="h1">{dictionary.pages.aboutUs.subtitle}</Typography>
      <Box className="content">
        <Box className="background">
          <img
            src={extended ? backgroundline : backgroundlinetop}
            alt="background line"
            loading="lazy"
          />
        </Box>
        <Element name="block1">
          <Box className="block block1">
            <Box className="image">
              <img src={image1} alt="Anja Bijnens" loading="lazy" />
            </Box>
            <Box className="text">
              <Typography variant="h2">Hallo!</Typography>
              <Typography variant="h4">
                Mijn naam is Anja en het gezicht achter de-Cameleon.
              </Typography>
              <Typography variant="h4">
                Een veilige plek kunnen creëren waar mensen zichzelf mogen zijn
                en aan de slag kunnen met hun hulpvragen, is een droom die
                uitkomt.
              </Typography>
              <Typography variant="h4">
                Kinderen, jongeren en volwassenen weer in hun krachten zetten is
                waar ik het voor doe, dat maakt mij oprecht gelukkig.
              </Typography>
              <Link to="block2" smooth={true} duration={500} offset={-150}>
                <CaretCircleDown
                  size={64}
                  color="#8AC219"
                  className="nextBlockButton"
                />
              </Link>
            </Box>
          </Box>
        </Element>
        <Element name="block2">
          <Box className="block block2">
            <Box className="text">
              <Typography variant="h4">
                <strong>Naast</strong> coach ben ik getrouwd met Peter en mama
                van Wout, Wannes en Janne. Alle drie ondertussen al
                jongvolwassenen en alle drie met hun eigen unieke
                persoonlijkheid en talenten.
              </Typography>
              <Typography variant="h4" className="smileytext">
                Je mag mij gerust als mama ervaringsdeskundige noemen met een
                zoon met een diagnose ASS en een dochter met een vermoeden van.
              </Typography>
              <Link to="block3" smooth={true} duration={500} offset={-100}>
                <CaretCircleDown
                  size={64}
                  color="#C219A3"
                  className="nextBlockButton"
                />
              </Link>
            </Box>
            <Box className="image">
              <img
                src={image2}
                alt="Autism doesn't come with a manual, it comes with a family that never gives up"
                loading="lazy"
              />
            </Box>
          </Box>
        </Element>
        <Element name="block3">
          <Box className="block block3">
            <Box className="image">
              <img
                src={image3}
                alt="Anja Bijnens at a art show"
                loading="lazy"
              />
            </Box>
            <Box className="text">
              <Typography variant="h4">
                <strong>Ik</strong> heb een passie voor Kunst en schilder op
                doek en op sculpturen in mijn eigen Atelier. Hier kan ik mij
                helemaal terugtrekken in mijn eigen wereld. Schrik dus niet als
                je in mijn praktijk kleurrijke schilderijen en sculpturen
                tegenkomt.
              </Typography>
              <Typography variant="h4">
                Na 25 jaar leerkracht buitengewoon lager onderwijs, waarvan de
                laatste zeven jaar als klastitularis en zorgleerkracht voor
                kinderen met autisme (type 9), was het tijd voor een nieuwe
                uitdaging!
              </Typography>
              <Typography variant="h4">
                Het is voor mij een hele zoektocht geweest naar mezelf en naar
                manieren om mijn leven opnieuw vorm te geven. Ik denk dat vele
                anderen soortgelijke moeilijkheden tegenkomen bij een vermoeden
                van of een late diagnose autisme. Ook ik heb gedurende mijn
                leven heel wat eigenschappen gecamoufleerd.
              </Typography>
              <Link to="block4" smooth={true} duration={500} offset={-90}>
                <CaretCircleDown
                  size={64}
                  color="#0D919F"
                  className="nextBlockButton"
                />
              </Link>
            </Box>
          </Box>
        </Element>
        <Element name="block4">
          <Box className="block block4">
            <Box className="text">
              <Typography variant="h4">
                <strong>Mijn</strong> benadering is gebaseerd op Acceptatie en
                Commitment Therapie (ACT), wat betekent dat we samen zullen
                werken aan het accepteren van wie je bent, terwijl we
                doelgericht stappen nemen om te bereiken wat voor jou belangrijk
                is.
              </Typography>
              <Typography variant="h4">
                Of je nu op zoek bent naar ondersteuning bij het begrijpen van
                autisme, het omgaan met uitdagingen in het dagelijks leven, of
                het ontwikkelen van vaardigheden om je doelen te bereiken, ik
                ben hier om je te begeleiden op jouw unieke reis.
              </Typography>
              <Link to="block5" smooth={true} duration={500} offset={0}>
                <CaretCircleDown
                  size={64}
                  color="#C0C0C0"
                  className="nextBlockButton"
                  onClick={() => setExtended(true)}
                />
              </Link>
            </Box>
            <Box className="image">
              <img src={image4} alt="coaching" loading="lazy" />
            </Box>
          </Box>
        </Element>
        <Button onClick={() => setExtended(!extended)}>Meer weten</Button>
        <Collapse in={extended} timeout={{ enter: 1000, exit: 500 }}>
          <Element name="block5">
            <Box className="block block5">
              <Box className="image">
                <img src={image5} alt="studying" loading="lazy" />
              </Box>
              <Box className="text">
                <Typography variant="h4">
                  <strong>Ik</strong> volg regelmatig opleidingen en vormingen
                  om mezelf te blijven ontwikkelen.
                </Typography>
                <Typography variant="h4">
                  Voor mijn professionele groei als coach en mij te laten
                  reflecteren over mijn eigen handelen volg ik individuele
                  supervisie sessies.
                </Typography>
                <Link to="block1" smooth={true} duration={500} offset={-300}>
                  <CaretCircleUp
                    size={64}
                    color="#2E2E2E"
                    className="nextBlockButton"
                  />
                </Link>
              </Box>
            </Box>
          </Element>
          <Box className="block block6">
            <Box className="row row1">
              <Typography variant="h2">Opleidingen/ Vormingen</Typography>
            </Box>
            <Box className="row">
              <Box className="column">
                <Typography variant="h3">Opleidingen</Typography>
                <ul>
                  <li>
                    <Typography variant="h4">
                      Bachelor lerarenopleiding Hasselt
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Banaba buitengewoon onderwijs UCLL
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Sociale vaardigheidstraining voor kinderen (o.a. axenroos)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Nieuwe autoriteit/geweldloos verzet
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Gecertificeerd trainer Brain Blocks
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Gecertificeerd trainer Autismevriendelijke coach - Autisme
                      Centraal
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Gecertificeerd trainer ACT (acceptance & commitment
                      therapy)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Hoe autisme uitleggen aan mensen met autisme (leerbewijs)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Tekenpraat – Sterkmakers in Autisme
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Creatief coachen met Kunst - Artevelde Hogeschool
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Jaaropleiding Kinder- en jongerencoach via Kanteel
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Duplo methodiek - Rapunzel Diest
                    </Typography>
                  </li>
                </ul>
              </Box>
              <Box className="column">
                <Typography variant="h3">Vormingen Autisme Centraal</Typography>
                <ul>
                  <li>
                    <Typography variant="h4">Autisme en camouflage</Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Mensen met autisme motiveren
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Emotieregulatie bij mensen met autisme
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Uitstelgedrag begrijpen en aanpakken
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Suïcidaliteit en autisme
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">Autisme en daten</Typography>
                  </li>
                  <li>
                    <Typography variant="h4">
                      Praktische aanpak van faalangst bij ASS
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h4">Gamen en autisme</Typography>
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </StyledAboutUs>
  );
};
