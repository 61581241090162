import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledContact = styled(Box)(({ theme }: StyledComponentProps) => ({
  padding: "2rem 10rem",
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  h1: {
    color: theme.color.dark,
    textAlign: "center",
  },
  ".description": {
    width: "100%",
    textAlign: "center",
    color: theme.color.dark,
    paddingInline: "10rem",
    boxSizing: "border-box",
    fontWeight: "bold",
  },
  ".row": {
    width: "100%",
    display: "flex",
    gap: "3rem",
    ".map": {
      width: "100%",
      height: "30rem",
      borderRadius: "25px",
      overflow: "hidden",
      boxShadow: `0px 0px 3px 3px ${theme.contrast.lighter}`,
    },
  },
  ".info": {
    width: "50rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "flex-start",
    ".divider": {
      width: "7.5rem",
      height: ".25rem",
      background: theme.color.dark,
      marginLeft: "1.5rem",
    },
    ".content": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "1rem",
    },
    h2: {
      color: theme.color.main,
      fontWeight: "bold",
      width: "100%",
      height: "min-content",
      fontSize: "3rem",
      lineHeight: "5rem",
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    padding: "5rem 2rem",
    flexDirection: "column",

    ".description": {
      paddingInline: "5rem",
    },
    ".row": {
      flexDirection: "column",
      alignItems: "center",
    },
    ".info": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      justifyContent: "flex-start",
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    padding: "5rem 1rem",
    flexDirection: "column",
    ".description": {
      paddingInline: "1rem",
    },
    ".row": {
      flexDirection: "column",
    },
    ".info": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      justifyContent: "flex-start",
      h2: {
        textAlign: "center",
      },
    },
  },
}));
