import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledNavbar = styled(Box)(({ theme }: StyledComponentProps) => ({
  height: "7.5rem",
  position: "relative",
  paddingInline: "5rem",
  filter: `drop-shadow(3px 3px 3px ${theme.contrast.dark})`,
  background: theme.color.background,
  zIndex: "99",
  ".navbar": {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    zIndex: "99",
    justifyContent: "space-between",
    paddingTop: "1rem",
    dropShadow: `2px 2px 2px 2px${theme.contrast.lighter}`,
    ".logoWrapper": {
      display: "flex",
      position: "relative",
      width: "20rem",
      img: {
        width: "100%",
        height: "5rem",
        objectFit: "contain",
      },
    },
    ".navlinks": {
      display: "flex",
      gap: "3rem",
      alignItems: "center",
    },
    ".menuIcon": {
      display: "none",
    },
  },

  ".navMenu": {
    background: theme.color.background,
    position: "absolute",
    paddingTop: "7.5rem",
    zIndex: "-2",
    top: "0",
    width: "100%",
    left: "0",
    height: "15rem",
    boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
    ".navlinks": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      height: "100%",
      flexDirection: "column",
    },
    ".shape": {
      top: "17rem",
    },
  },

  ".shape": {
    width: "100%",
    background: theme.color.background,
    position: "absolute",
    zIndex: "-1",
    clipPath: "ellipse(96% 14% at 50% 7%)",
    height: "40rem",
    top: "0",
    left: "0",
  },

  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    height: "5rem",
    paddingInline: "5rem",
    ".navbar": {
      ".navlinks": {
        display: "none",
      },
      ".logoWrapper": {
        width: "15rem",
        img: {
          width: "100%",
          objectFit: "contain",
        },
      },
      ".menuIcon": {
        display: "flex",
      },
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    paddingInline: "2rem",
  },
}));
