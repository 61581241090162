import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledHomeCard = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    width: "20rem",

    background: theme.contrast.lighter,
    position: "relative",
    borderRadius: "60px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "3rem",
    ".content": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: ".5rem",
      h3: {
        minHeight: "2.1rem",
      },
      h4: {
        lineheight: "20px",
        textAlign: "center",
      },
    },
    ".wave": {
      position: "absolute",
      img: {
        width: "100%",
        height: "100%",
      },
    },
    ".top": {
      top: "0",
      width: "100%",
      height: "2rem",
      transform: "translateY(-50%)",
    },
    ".right": {
      right: "0",
      top: "0",
      height: "100%",
      width: "2rem",
      transform: "translateX(50%)",
    },
    ".bottom": {
      bottom: "0",
      width: "100%",
      height: "2rem",
      transform: "translateY(50%)",
    },
    ".left": {
      left: "0",
      top: "0",
      height: "100%",
      width: "2rem",
      transform: "translateX(-50%)",
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
      height: "fit-content",
    },
  })
);
