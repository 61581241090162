import { Box, keyframes, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledAboutUs = styled(Box)(({ theme }: StyledComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  minWidth: "100%",
  h1: {
    textAlign: "center",
    color: theme.color.dark,
    paddingBlock: "2rem",
  },
  ".content": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    paddingBottom: "5rem",
    width: "80%",
    ".background": {
      width: "100%",
      top: "0",
      position: "absolute",
      zIndex: "-1",
      padding: "5% 16%",
      boxSizing: "border-box",
      img: {
        width: "100%",
        objectFit: "contain",
      },
    },
    button: {
      width: "30%",
    },
    ".block": {
      display: "flex",
      alignItems: "center",
      marginTop: "3rem",
      ".image": {
        width: "30rem",
        height: "30rem",
        img: {
          width: "100%",
        },
      },
      ".text": {
        width: "40%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        ".smileytext": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          svg: {
            paddingInline: ".25rem",
          },
        },
        ".nextBlockButton": {
          animation: `${shake()} 1s linear infinite`,
          cursor: "pointer",
        },
      },
    },
    ".block1": {
      gap: "10rem",
      justifyContent: "flex-start",
    },
    ".block2": {
      gap: "10rem",
      justifyContent: "flex-end",
      paddingRight: "5rem",
      paddingTop: "2rem",
      ".image": {
        width: "25rem",
        height: "25rem",
      },
    },
    ".block3": {
      gap: "10rem",
      justifyContent: "flex-start",
      ".image": {
        width: "25rem",
        height: "25rem",
      },
      ".text": {
        paddingTop: "5rem",
      },
    },
    ".block4": {
      gap: "10rem",
      justifyContent: "center",
      paddingTop: "5rem",
      marginTop: "5rem",
      ".image": {
        width: "20rem",
        height: "20rem",
      },
      ".text": {
        paddingTop: "5rem",
      },
    },
    ".block5": {
      gap: "10rem",
      justifyContent: "center",
      paddingTop: "15rem",
      ".image": {
        width: "25rem",
        height: "25rem",
      },
      ".text": {
        paddingTop: "5rem",
      },
    },
    ".block6": {
      width: "70%",
      paddingTop: "5rem",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      h2: {
        textAlign: "center",
        color: theme.color.dark,
      },
      ".row": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        h3: {
          fontWeight: "bold",
          color: theme.color.dark,
        },
        h4: {
          textAlign: "center",
          paddingBlock: ".5rem",
          width: "100%",
        },
        ul: {
          li: {
            color: theme.color.dark,
            h4: {
              textAlign: "start",
            },
          },
        },
      },
      ".row1": {
        flexDirection: "column",
      },
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    h1: {
      paddingBlock: "0",
      paddingTop: "5rem",
      paddingBottom: "1rem",
    },
    ".content": {
      width: "80%",
      ".background": {
        display: "none",
      },
      button: {
        width: "70%",
      },
      ".block": {
        gap: "3rem",
        flexDirection: "column",
        ".image": {
          width: "20rem",
          height: "20rem",
        },
        ".text": {
          width: "90%",
          textAlign: "center",
          ".smileytext": {
            display: "inline-block",
            padding: "0",
            svg: {
              verticalAlign: "middle",
              padding: "0.15rem",
            },
          },
        },
      },
      ".block2": {
        flexDirection: "column-reverse",
        paddingRight: "0",
        paddingTop: "2rem",
        ".image": {
          width: "22.5rem",
          height: "22.5rem",
        },
      },
      ".block3": {
        ".image": {
          width: "20rem",
          height: "20rem",
        },
        ".text": {
          paddingTop: "1rem",
        },
      },
      ".block4": {
        flexDirection: "column-reverse",
        paddingTop: "3rem",
        marginTop: "0",
        ".image": {
          width: "22.5rem",
          height: "22.5rem",
        },
        ".text": {
          paddingTop: "2rem",
        },
      },
      ".block5": {
        paddingTop: "0rem",
        ".image": {
          width: "22.5rem",
          height: "22.5rem",
        },
        ".text": {
          paddingTop: "2rem",
        },
      },
      ".block6": {
        width: "90%",
        paddingTop: "2rem",
        flexDirection: "column",
        gap: "1rem",
        h2: {
          textAlign: "start",
          fontSize: "2rem",
        },
        ".row": {
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          h3: {
            fontSize: "1.5rem",
            textAlign: "center",
          },
          h4: {
            fontSize: "1rem",
          },
          ul: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            li: {
              width: "fit-content",
              h4: {
                textAlign: "center",
              },
            },
          },
        },
        ".row1": {
          flexDirection: "column",
        },
      },
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    ".content": {
      width: "100%",
      ".block": {
        gap: "2rem",
        ".image": {
          width: "17.5rem",
          height: "17.5rem",
        },
      },
      ".block2": {
        ".image": {
          width: "20rem",
          height: "20rem",
        },
      },
      ".block3": {
        ".image": {
          width: "17.5rem",
          height: "17.5rem",
        },
      },
      ".block4": {
        ".image": {
          width: "20rem",
          height: "20rem",
        },
        ".text": {
          paddingTop: "2rem",
        },
      },
      ".block5": {
        ".image": {
          width: "20rem",
          height: "20rem",
        },
      },
      ".block6": {
        h2: {
          fontSize: "1.8rem",
        },
        ".row": {
          alignItems: "start",
          h3: {
            fontSize: "1.5rem",
            textAlign: "start",
          },
          h4: {
            fontSize: "1rem",
          },
          ul: {
            alignItems: "start",
            li: {
              width: "100%",
              h4: {
                textAlign: "start",
              },
            },
          },
        },
        ".row1": {
          flexDirection: "column",
        },
      },
    },
  },
}));

const shake = () => keyframes`
  0% {
    transform: rotate(0)
  }
  25% {
    transform: rotate(5deg)
  }
  50% {
    transform: rotate(-5deg)
  }
  100% {
    transform: rotate(0)}
  }
`;
