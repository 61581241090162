import { Box } from "@mui/material";
import divider from "../../images/divider.png";

export const Divider = () => {
  return (
    <Box className="divider">
      <img src={divider} alt="homepage divider" />
    </Box>
  );
};
